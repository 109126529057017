import React from 'react';
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

import { ThemeContext } from '../../context/theme-context';

import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import ContactBlock from '../../components/contactBlock';
import NextCase from '../../components/nextCase';
import IntroBlock from '../../components/introBlock';
import BannerResult from '../../components/bannerResult';
import SimplePhrase from '../../components/simplePhrase';
import ChromaticPalette from '../../components/chromaticPalette';

const CasesPage = ({ location }) => {

    return (

        <Layout bg="white" location={location}>

            <Helmet>
                <script src={withPrefix('wrap.js')} type="text/javascript" />
                <title>ATUO - Cases - Firstoeat</title>
            </Helmet>

            <div className="container mx-auto">

                <Title
                    title="Firstoeat"
                    titleColor="#E01C2D"
                    description="Porque comida <br/> boa pode ser a <br/> qualquer hora."
                    smallDescription="[experiência, sabor, praticidade]"
                />

                <BannerImage src={`/firstoeat/firstoeat_content_one.jpg`} title="Firstoeat" />

                <IntroBlock
                    logo={`/firstoeat/firstoeat_logo.svg`}
                    alt="Firstoeat"
                    description="Empresa nascida em Portugal com objetivo de trazer novas experiências nos melhores restaurantes e gerar fluxo em horários de baixo movimento. Desafio de se posicionar no mercado europeu, se adaptar à cultura e gerar uma experiência agradável desde o primeiro contato com o consumidor e aumentar seu market share."
                    list={['Estratégia & Inovacão', 'Pesquisa & Inteligência', 'Design de Experiência do Consumidor', 'Cultura, Conteúdo & Social']} />

                <SimplePhrase paddingX={24}>Criar experiências ampliando o marketshare de uma marca que oferece um cardápio de experiências a qualquer hora do dia.</SimplePhrase>

                <div className="flex justify-center gap-x-16 md:px-24 items-center my-40 md:my-60 flex-col md:flex-row">
                    <div className="md:w-1/2 text-2xl order-2 md:order-1">
                        <p>Criada com o nome de Firstoeat, após a adaptação para o mercado europeu, a marca virou Firstoeat. O nome está ligado à ideia de aproveitar os primeiros horários de abertura dos restaurantes para obter descontos.</p>
                    </div>

                    <div className="md:w-1/2 order-1 md:order-2 text-center mb-24 md:mb-0">
                        <img src={`/firstoeat/firstoeat_stamp.svg`} alt="Principal" className="inline" />
                    </div>
                </div>

                <ChromaticPalette 
                    description={"Utilizando os tons de vermelho e amarelo vivos, a ideia é transmitir boas vibrações, como felicidade e otimismo. O vermelho representa desejo e força, atraindo o olhar humano. Já o amarelo desperta o apetite."}
                >
                    <div className="py-24 md:py-16 flex flex-wrap gap-y-10 gap-x-9 md:gap-x-32 justify-evenly md:justify-center">
                        <div className="cardColorBlock" data-aos="fade-up" data-aos-delay="0" style={{ backgroundColor: '#e01c2d' }}>#e01c2d</div>
                        <div className="cardColorBlock" data-aos="fade-up" data-aos-delay="300" style={{ backgroundColor: '#fea740' }}>#fea740</div>
                    </div>
                </ChromaticPalette>

                <div className="grid grid-cols-1 md:grid-cols-2 my-40 md:my-60">
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-2xl md:text-4xl mb-10 font-semibold md:leading-tight">Uma experiência gastronômica marcante com os mais variados tipos de culinária do mundo a apenas um toque de distância.</p>
                        <p className="text-xl md:text-2xl">Um app idealizado por uma startup e criado pela Atuo foi lançado. Com acesso intuitivo baseado nas heurísticas de usabilidade e UX, o app proporciona diversas oportunidades para os clientes nos melhores restaurantes com descontos exclusivos para movimentar as lojas nos primeiros horários, que antes havia baixo fluxo.</p>
                    </div>

                    <div className="md:px-16 mt-24 md:mt-0">
                        <img src={`/firstoeat/firstoeat_content_two.jpg`} alt="Cervogia" />
                    </div>
                </div>

                <SimplePhrase paddingX={40}>Tecnologia criando experiências gastronômicas marcantes</SimplePhrase>

                <div className="my-40">

                    <div className="text-center">
                        <img src={`/firstoeat/firstoeat_content_three.png`} alt="Cervogia" className="" />
                    </div>

                    <p className="text-right mt-4 text-xl">[intuitivo, flexível, personalizável]</p>
                </div>

                <div className="my-40 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-7">
                    <p className="text-2xl md:text-4xl font-semibold leading-tight md:leading-normal">
                        Não basta ter uma ideia. É preciso que ela se conecte com o público e atenda às suas necessidades.
                    </p>
                    <p className="text-xl md:text-2xl md:mt-36">
                    Para dar base às ideias, foi realizada ampla pesquisa com os usuários de Portugal a fim de entender seu universo, sua cultura e seus desejos, que, mesmo parecendo próximos aos brasileiros, tinham hábitos diferentes. <br /><br />
                        Foram criados três perfis de personas para nortear a criação dos conteúdos da plataforma, das mídias e da comunicação.
                    </p>
                </div>

                <div className="mt-24 mb-40 md:mt-16">
                    <img src={`/firstoeat/firstoeat_content_four.jpg`} alt="Cervogia" />
                </div>

                <SimplePhrase paddingX={40}>Uma marca com presença digital, capaz de gerar oportunidades e experiências inesquecíveis para seus clientes.</SimplePhrase>
            </div>


            <BannerResult
                bg="#E01C2D"
                list={['Experiência de marca', 'Marca com propósito', 'Presença digital', 'Melhor experiência de compra']} />

            <NextCase
                link="/cases/code-and-soul"
                title="Code and Soul"
                description="Usabilidade e autoridade para uma marca cheia de personalidade."
                image="/code-and-soul-next.png" />

            <ThemeContext.Consumer>
                {cx => (
                    <ContactBlock
                        description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
                        weather={cx?.cookies?.weather}
                    />
                )}
            </ThemeContext.Consumer>

            <Footer />
        </Layout>
    );
};

export default CasesPage;
